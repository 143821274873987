import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../components/layout"
import Header from "../components/section/header"
import Seo from "../components/seo"

export default function about({ pageContext: { data }, location }) {
  const about = data
  return (
    <Layout>
      <Seo title={about.frontmatter.title} pathname={location.pathname} />
      <Header title={about.frontmatter.title} />
      <Content data={about.html} />
    </Layout>
  )
}

const Content = ({ data }) => {
  return (
    <section className="max-w-6xl w-11/12 rounded-md  mt-6 px-3 2xl:w-full mx-auto grid md:grid-cols-2  gap-3 py-6 md:py-12">
      <div>
        <StaticImage
          src="../images/image_from_ios.jpeg"
          alt="Tony"
          quality={100}
          className="rounded-md overflow-hidden"
        />
      </div>
      <div
        className="prose prose-sm text-black bg-primary p-4 rounded"
        dangerouslySetInnerHTML={{ __html: data }}
      />
    </section>
  )
}
